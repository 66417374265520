<template>
  <div class="spaceConfigPainel" data-anima="top">
    <div class="coluna">
      <div class="spaceColor">
        <h1>{{$t('settings.advanced.txt1')}}</h1>
        <div class="line"></div>
        <div class="subTitle">
          {{$t('settings.advanced.txt2')}}
        </div>
        <div class="flex mt-2">
          <button @click="openModalDeletePainel" class="btnConfigDominio">
            {{$t('settings.advanced.txt1')}}
          </button>
        </div>
      </div>
    </div>
    <ModalDeletarSite :selectedSite="selectedSite" :allSites="allSites"></ModalDeletarSite>
  </div>
</template>

<script>
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import { mapGetters } from "vuex";

import ModalDeletarSite from "@/components/ModalDeletarSite.vue";
export default {
  data() {
    return {
      allSites: "",
      selectedSite: "",
    };
  },
  components: {
    ModalDeletarSite,
  },
  computed: {
    ...mapGetters({
      getMe: "auth/getMe",
    }),
  },
  mounted() {
    this.getCurrentSiteAndMember();
  },
  methods: {
    getCurrentSiteAndMember() {

      this.selectedSite = this.getMe.current.currentSite;
      this.allSites = this.getMe;

    },
    openModalDeletePainel() {
      this.$root.$emit("bv::show::modal", "modal-delete-site", "#btnShow");
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 15px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 15px;
}

.spaceConfigPainel {
  display: grid;
  gap: 22px;
  grid-template-columns: 1fr 1fr;
}

.coluna {
  width: 100%;
  padding-left: 10px;
}

.spaceColor {
  margin-top: 20px;
  margin-bottom: 20px;

  .text {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: var(--fontcolor2);
    margin-bottom: 5px;
  }

  .text2 {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor2);
    margin-bottom: 5px;
  }
}

.textTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--fontcolor);
  display: flex;
}

.subTitle {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #81858e;
}

.flex {
  display: flex;
}

.btnConfigDominio {
  background: #fc2b36;
  border: 1px solid #fc2b36;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 30px;
  height: 45px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 251.29px;
}
</style>
