<template>
  <Layout>
    <div class="grid-base-menu">
      <MenuSlide @changeType="changeType" :feature_access_free="feature_access_free" />
      <Configuracoes v-if="type === 'geral'" />
      <Custom v-if="type === 'custom'" />
      <MeusPlanos class="pr-3" v-if="type === 'plans'" />
      <Avancado class="pr-3" v-if="type === 'advanced'" />
      <CamposPersonalizados class="pr-3" v-if="type === 'custom_camp'" />
      <AcessoGratuito class="pr-3" v-if="type === 'free'"/>
      <!-- <MinhaConta class="pr-3" v-if="type === 'account'" /> -->
      <Webhooks class="pr-3" v-if="type === 'webhooks'" />
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/Layout.vue"
import MenuSlide from "@/components/configs/MenuSlide.vue"
import Configuracoes from "@/components/configs/Configuracoes.vue"
import Custom from "@/components/configs/Custom.vue"
import Avancado from "@/components/configs/Avancado.vue"
import MeusPlanos from "@/components/MeusPlanos.vue"
import MinhaConta from "@/components/configs/MinhaConta.vue"
import CamposPersonalizados from "@/components/configs/CamposPersonalizados.vue"
import Cookies from "js-cookie"
import AcessoGratuito from "@/components/AcessoGratuito.vue";
import Webhooks from "@/components/configs/Webhooks.vue"

import MetaService from "@/services/resources/MetaService"

const serviceMeta = MetaService.build()

export default {
  components: {
    Layout,
    MenuSlide,
    Configuracoes,
    MeusPlanos,
    Avancado,
    AcessoGratuito,
    Custom,
    CamposPersonalizados,
    MinhaConta,
    Webhooks
  },
  data() {
    return {
      type: "geral",
      color: "",
      theme: "light",
      themeColor: "Claro",
      feature_access_free: false,
    };
  },
  mounted() {
    // this.getColor();
    // this.getThemeFixed();
    this.getFreeCourses();
  },
  methods: {
    changeType(type) {
      this.type = type;
    },
    // insertTheme() {
    //   const d = document;
    //   var theme = Cookies.get("themeClub");
    //   if (theme === undefined || theme === null) {
    //     d.documentElement.setAttribute("data-theme", "light");
    //     document.getElementById("slider").checked = false;
    //   } else {
    //     d.documentElement.setAttribute("data-theme", theme);
    //     if (theme === "light") {
    //       document.getElementById("slider").checked = false;
    //     } else {
    //       document.getElementById("slider").checked = true;
    //     }
    //   }
    //   this.$root.$emit("loadTheme")
    //   this.$root.$emit('loadsettheme')
    // },
    getFreeCourses() {
      serviceMeta
        .search("keys[]=feature_access_free")
        .then(resp => {
          if (resp.feature_access_free === 'on' ) {
            this.feature_access_free = true;
          } else {
            this.feature_access_free = false;
          }
        })
        .catch(err => console.error(err))
    },
    // getThemeFixed() {
    //   serviceMeta
    //     .search("keys[]=theme_fixed_painel")
    //     .then(resp => {
    //       if (resp.theme_fixed_painel === "on") {
    //         this.getThemeFixedColor()
    //       } else {
    //         // this.insertTheme()
    //       }
    //     })
    //     .catch(err => console.error(err))
    // },
    // getThemeFixedColor() {
    //   serviceMeta
    //     .search("keys[]=theme_fixed_painel_color")
    //     .then(resp => {
    //       const d = document;
    //       if (resp.theme_fixed_painel_color === "light") {
    //         Cookies.set("themeClub", "light", { expires: 365 })
    //         this.theme = "light"
    //         this.themeColor = "Claro"
    //       } else {
    //         Cookies.set("themeClub", "dark", { expires: 365 })
    //         this.theme = "dark"
    //         this.themeColor = "Escuro"
    //       }
    //       this.existColorFixed = true
    //       d.documentElement.setAttribute("data-theme", this.theme)
    //       this.$root.$emit("loadTheme")
    //       this.$root.$emit("loadsettheme")
    //     })
    //     .catch(err => console.error(err))
    // },
    // getColor() {
    //   serviceMeta.search("keys[]=main_color").then((resp) => {
    //     this.color = resp.main_color;
    //     if (this.color !== null) {
    //       this.$root.$emit("changedmaincolor", this.color);
    //       const d = document;
    //       d.documentElement.setAttribute("data-color", this.color)
    //       const headTag = document.getElementsByTagName('head')[0];
    //       const styleTag = document.createElement("style");

    //         styleTag.innerHTML = `
    //         :root {
    //             --maincolor: ${this.color};
    //             --maincolorn: ${this.$func.convertColor(this.color, 210)};
    //             --maincolortrans: ${this.color + '0f'};
    //         }
    //         `;
    //         headTag.appendChild(styleTag);
    //     }
    //   })
    // }
  }
}
</script>

<style scoped lang="scss">
.grid-base-menu {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr;
  margin-top: 50px;
  padding-left: 50px;
  gap: 30px;
}

@media screen and (max-width: 768px) {
  .grid-base-menu {
    grid-template-columns: 1fr;
    margin-left: 0px;
    padding: 0px 5px;
    display: block;
  }
  .base {
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
  }
  .gridColum {
    grid-template-columns: 1fr !important;
  }
}
</style>